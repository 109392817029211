// Assets
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

const HelpDialog = ({ label, children, isHelpOpen, toggleHelpDialog, showLogo = false }) => {
    return (
        <div id="help-wrapper" className={ isHelpOpen ? "open" : null }>
            <div className="page">
                <div className="topbar fixed">
                    <div className="left" style={{ minWidth: 60 }}></div>
                    <div className="center">
                        <div className="pill bg-silver">{ label }</div>
                    </div>
                    <div className="right">
                        <div className={`square-button top-right close`}>
                            <CloseIcon onClick={() => { toggleHelpDialog(false) }} />
                        </div>
                    </div>
                </div>

                <div className="help">
                    { showLogo &&
                        <div className="header">
                            <img className="logo" src="/img/favicons/android-chrome-384x384.png" alt="Comune di Lodi" />
                        </div>

                    }

                    <div className="body">
                        { children }
                    </div>

                    <div className="footer">
                        <div className="buttons">
                            <div className="button primary" onClick={() => { toggleHelpDialog(false) }}>HO CAPITO</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpDialog;
