// React
import React, { useState } from 'react';

// Styles and Assets
import './Textarea.scss';

const Password = (props) => {
    const [ length, setLength ] = useState(0);

    const onKeyUp = (e) => {
        setLength(e.target.value.length);
    }

    return (
        <div className="textarea">
            <div className="length">{ length } / { props.maxlength }</div>
            <textarea
                lang={ props.lang }
                name={ props.name }
                rows={ props.rows }
                maxlength={ props.maxlength }
                onChange={ props.onChange }
                onKeyUp={ onKeyUp }
            ></textarea>
        </div>
    )
}

export default Password;
