// Components
import LogoButton from '../../components/Buttons/logo';

// Styles and Assets
import './Privacy.scss';
import headerImage from '../../assets/img/lodi_01.jpg';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/sandwich.svg'

const Privacy = ({ openSidebar }) => {
    return (
        <div className="page fixed privacy">
            <div className="topbar">
                <div className="left">
                    <LogoButton />
                </div>
                <div className="center">
                    <div className="pill bg-silver">PRIVACY</div>
                </div>
                <div className="right">
                    <div className="square-button hamburger" onClick={() => { openSidebar(true) }}>
                        <HamburgerIcon />
                    </div>
                </div>
            </div>

            <div className="header" style={{ backgroundImage: `url(${headerImage})` }}></div>

            <div className="body">
                <p>
                    Ai sensi del D.lgs 101/2018, questa informativa è fornita agli utenti che interagiscono con i servizi web del Comune di Lodi accessibili per via telematica a partire dall'indirizzo www.comune.lodi.it, corrispondente alla pagina iniziale del sito ufficiale del Comune di Lodi.
                </p>
                <p>
                    La presente informativa descrive le modalità di gestione del sito web ufficiale del Comune di Lodi e NON di tutti gli altri siti web esterni consultabili dall'utente tramite link.
                </p>

                <hr />

                <h2><span>1.</span> TIPI DI DATI TRATTATI</h2>
                <h3><span>1.1.</span> Dati di navigazione - log files</h3>
                <p>
                    I sistemi informatici e gli applicativi dedicati al funzionamento di questo sito web rilevano, nel corso del loro normale funzionamento, alcuni dati (la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet) non associati a Utenti direttamente identificabili.
                    <br /><br />
                    Tra i dati raccolti sono compresi gli indirizzi IP e i nomi di dominio dei computer utilizzati dagli Utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri riguardanti il sistema operativo e l'ambiente informatico utilizzato dall'Utente.
                    <br /><br />
                    Questi dati vengono trattati, per il tempo strettamente necessario, al solo fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il regolare funzionamento.
                </p>

                <h3><span>1.2.</span> Dati forniti volontariamente dall'utente</h3>
                <p>
                    L'invio volontario ed esplicito di posta elettronica agli indirizzi indicati nei differenti canali di accesso di questo sito e la compilazione dei "moduli" (maschere) specificamente predisposti comportano la successiva acquisizione dell'indirizzo e dei dati del mittente/utente, necessari per rispondere alle istanze prodotte e/o erogare il servizio richiesto.
                    <br /><br />
                    Specifiche informative di sintesi saranno riportate o visualizzate nelle pagine del sito predisposte per particolari servizi a richiesta.
                </p>

                <h2><span>2.</span> FINALITA' DEL TRATTAMENTO DEI DATI</h2>
                <p>
                    Il Comune di Lodi effettua trattamenti di dati personali nei limiti di quanto ciò sia strettamente necessario allo svolgimento delle funzioni istituzionali, escludendo il trattamento quando le finalità perseguite possono essere realizzate mediante dati anonimi o modalità che permettono di identificare l'interessato solo in caso di necessità.Specifiche finalità, relative ai singoli trattamenti, potranno essere segnalate in maniera dettagliata nell'ambito dei vari canali di accesso. All'interno di essi l'Utente potrà trovare informazioni integrative sul trattamento dei dati personali.
                </p>

                <h2><span>3.</span> MODALITA' DEL TRATTAMENTO</h2>
                <p>
                    I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire gli scopi per cui sono raccolti.
                </p>

                <h2><span>4.</span> FACOLTATIVITA' DEL CONFERIMENTO DEI DATI</h2>
                <p>
                    L'utente è libero di fornire i dati personali riportati nei moduli di richiesta dei vari servizi offerti nel sito; il mancato conferimento dei dati richiesti obbligatoriamente, comporterà l'impossibilità di ottenere il servizio.
                </p>

                <h2><span>5.</span> COMUNICAZIONE E/O DIFFUSIONE DEI DATI</h2>
                <p>
                    I dati personali degli utenti che chiedono l'invio di materiale informativo (mailing-list, risposte a quesiti, avvisi e newsletter, atti e provvedimenti, ecc.) sono utilizzati unicamente al fine di eseguire il servizio o la prestazione richiesta e sono comunicati a terzi nei soli casi in cui:
                    <br />* ciò è necessario per l'adempimento delle richieste (es. servizio di spedizione della documentazione richiesta);
                    <br />* la comunicazione è imposta da obblighi di legge o di regolamento;
                    <br />* durante un procedimento legale.
                    <br />I trattamenti connessi ai servizi web di questo sito sono curati esclusivamente da personale tecnico del Comune di Lodi, incaricato del trattamento.
                </p>

                <h2><span>6.</span> IL "TITOLARE" DEL TRATTAMENTO</h2>
                <p>
                    Il "titolare" del trattamento dei dati, relativi a persone identificate o identificabili che hanno consultato questo sito, è il Comune di Lodi, con sede in: Lodi, Piazza Broletto, 1.
                </p>

                <h2><span>7.</span> DIRITTI DEGLI INTERESSATI</h2>
                <p>
                    Gli "interessati", ovvero le persone fisiche, le persone giuridiche, gli enti o le associazioni, cui si riferiscono i dati hanno il diritto, in qualunque momento, di ottenere la conferma dell'esistenza o meno dei medesimi dati e di conoscerne il contenuto e l'origine, verificarne l'esattezza o chiederne l'integrazione o l'aggiornamento, oppure la rettificazione ai sensi dell'art. 7 del D.Lgs. 30/06/2003,n.196. Ai sensi del medesimo articolo gli interessati hanno altresì il diritto di chiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati che li riguardano, trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento.
                </p>
            </div>
        </div>
    )
}

export default Privacy;
