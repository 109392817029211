// React
import { useState } from 'react';
import { Link } from "react-router-dom";

// Components
import Loader from '../../components/Loader';

// Styles and Assets
import './Forgot.scss';

const Forgot = () => {
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ isLoaderVisible, showLoader ] = useState(false);

    const [ data, setData ] = useState({
        email: null
    });

    const onReset = () => {
        showLoader(true);
        fetch(process.env.REACT_APP_API_BASE_URL + '/user/forgot', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((response) => {
            showLoader(false);
            if (response.status === 'success') {
                setUser(response.data.user);
                setSuccess(true);
            }
            else {
                setError(response.message);
            }
        })
        .catch((err) => {
            setError(err.message);
        });
    }

    const ResetButton = () => {
        if (data.email) {
            return <div className="button full-width primary" onClick={() => onReset()}>REIMPOSTA PASSWORD</div>
        }
        else {
            return <div className="button full-width secondary">REIMPOSTA PASSWORD</div>
        }
    }

    return (
        <div className="page forgot">
            <div className="body">
                <h2>Hai dimenticato la password?</h2>

                { !success &&
                    <>
                        <p>
                            Inserisci il tuo indirizzo email, e ti aiuteremo a impostare una nuova password!
                        </p>
                        <div className="form">
                            <div className="field">
                                <div className="name">Indirizzo email</div>
                                <div className="value">
                                    <input name="email" type="text" onChange={(e) => { setError(null); setData({ ...data, email: e.target.value }) }} />
                                </div>
                            </div>
                            { error &&
                                <div className="message">{ error }</div>
                            }
                            <div className="buttons">
                                <ResetButton />
                            </div>
                        </div>
                    </>
                }

                { success &&
                    <>
                        <p>
                            Abbiamo inviato una email al tuo indirizzo { user?.email }.
                        </p>
                        <p>
                            Controlla la tua casella di posta e segui le istruzioni che trovi nella email.
                        </p>
                        <div className="form">
                            <div className="buttons">
                                <div className="button primary full-width">
                                    <Link to="/">TORNA ALLA MAPPA</Link>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

            { isLoaderVisible &&
                <Loader />
            }
        </div>
    )
}

export default Forgot;
