// Components
import LogoButton from '../../components/Buttons/logo';

// Styles and Assets
import './Terms.scss';
import headerImage from '../../assets/img/lodi_03.jpg';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/sandwich.svg'

const Terms = ({ openSidebar }) => {
    return (
        <div className="page fixed privacy">
            <div className="topbar">
                <div className="left">
                    <LogoButton />
                </div>
                <div className="center">
                    <div className="pill bg-silver">CONDIZIONI D'USO</div>
                </div>
                <div className="right">
                    <div className="square-button hamburger" onClick={() => { openSidebar(true) }}>
                        <HamburgerIcon />
                    </div>
                </div>
            </div>

            <div className="header" style={{ backgroundImage: `url(${headerImage})` }}></div>

            <div className="body">
                <h2>Termini e condizioni</h2>
                <p>Le diamo il benvenuto su Laus.App!</p>
                <p>Accedendo a questa applicazione, Lei accetta i termini e le condizioni seguenti. Le chiediamo di non continuare ad usare Laus.App nel caso in cui non fosse d'accordo con tutti i termini e le condizioni in questa pagina.</p>

                <h2>Licenza</h2>
                <p>A meno che indicato altrimenti, il Comune di Lodi e/o gli affiliati con licenza detengono i diritti di proprietà intellettuale per tutto il materiale su Laus.App. Tutti i diritti di proprietà intellettuale sono riservati. Può accedervi tramite Laus.App per uso personale soggetto a restrizioni indicate nei termini e nelle condizioni seguenti.</p>
                <p>Non è consentito:</p>
                <ul>
                    <li>Copiare o ripubblicare il materiale di Laus.App</li>
                    <li>Vendere, noleggiare o fornire in sub-licenza il materiale di Laus.App</li>
                    <li>Riprodurre, duplicare o copiare il materiale di Laus.App</li>
                    <li>Ridistribuire contenuti di Laus.App</li>
                </ul>

                <p>Parti di questo sito offrono all'utente la possibilità di pubblicare e scambiare opinioni e informazioni in certe aree del sito. Il Comune di Lodi filtra, non modifica, pubblica e revisiona le segnalazioni prima di aggiungerli sul sito. Le segnalazioni non riflettono il punto di vista e l'opinione del Comune di Lodi, dei suoi agenti e/o dei suoi affiliati. I commenti riflettono punto di vista e opinione di colui che pubblica tale punto di vista o opinione. Nella misura consentita dalla legge, il Comune di Lodi non deve essere ritenuta responsabile per i commenti e non può ricevere richieste di risarcimento danni o spese causate dall'uso, dalla pubblicazione e/o dalla presenza dei commenti sulla applicazione.</p>

                <p>Il Comune di Lodi si riserva il diritto di monitorare tutti i commenti e rimuovere quelli che considera non appropriati, offensivi o in violazione con i presenti termini e condizioni.</p>

                <p>Lei garantisce che:</p>
                <ul>
                    <li>È autorizzato a pubblicare segnalazioni sulla applicazione sito e ha tutte le licenze e i permessi necessari per tale azione;</li>
                    <li>Le segnalazioni non violano nessun diritto di proprietà intellettuale, inclusi senza limitazione diritto d'autore, brevetti o marchi commerciali di terze parti;</li>
                    <li>Le segnalazioni non contengono materiale diffamatorio, calunnioso, offensivo, indecente o altrimenti illegale, provocando una violazione della privacy;</li>
                    <li>Le segnalazioni non verranno usate per sollecitare o promuovere business, attività commerciali o illegali.</li>
                    <li>Lei concede al Comune di Lodi una licenza non esclusiva di usare, riprodurre, modificare e autorizza altri a usare, riprodurre e modificare qualsiasi Sua segnalazione in ogni forma, formato o media.</li>
                </ul>

                <h2>Collegamenti ipertestuali ai nostri contenuti</h2>
                <p>Le organizzazioni seguenti potrebbero aggiungere collegamenti ipertestuali al nostro sito senza previa autorizzazione scritta:</p>
                <ul>
                    <li>Agenzie governative;</li>
                    <li>Motori di ricerca;</li>
                    <li>Testate giornalistiche;</li>
                    <li>Distributori di directory online potrebbero aggiungere un collegamento ipertestuale al nostro sito nello stesso modo fatto per altri siti web indicati;</li>
                    <li>Attività accreditate di sistema eccetto organizzazioni non-profit, società di beneficenza e gruppi di raccolta fondi per attività di carità che non possono aggiungere un collegamento ipertestuale al nostro sito.</li>
                    <li>Queste organizzazioni potrebbero aggiungere un collegamento ipertestuale alla nostra homepage, alle pubblicazioni e alle informazioni di altri siti a patto che il collegamento: (a) non sia ingannevole in alcun modo; (b) non implichi falsamente sponsorizzazione, pubblicità o approvazione del terzo che ha aggiunto il collegamento e dei suoi prodotti e/o servizi; (c) sia adatto al contesto del sito del terzo che ha aggiunto il collegamento.</li>
                </ul>

                <p>Potremmo considerare e approvare altre richieste di collegamento dai seguenti tipi di organizzazioni:</p>
                <ul>
                    <li>fonti di informazioni commerciali e/o per consumatori conosciute;</li>
                    <li>siti di community;</li>
                    <li>associazioni o altri gruppi che rappresentano attività di carità;</li>
                    <li>distributori di directory online;</li>
                    <li>portali internet;</li>
                    <li>agenzie di consulenza, legali e di revisione contabile;</li>
                    <li>istituti di istruzione e associazioni professionali.</li>
                </ul>

                <p>Approveremo le richieste di collegamento da queste organizzazioni se: (a) il collegamento non ci porrà in una posizione sfavorevole per noi stessi o per le nostre attività registrate; (b) l'organizzazione non ha precedenti negativi con noi; (c) il vantaggio dato dalla visibilità del collegamento ipertestuale compensa l'assenza per il Comune di Lodi; (d) il link è in un contesto di informazioni generali.</p>

                <p>Queste organizzazioni potrebbero aggiungere un collegamento alla nostra homepage a patto che il collegamento: (a) non sia ingannevole in alcun modo; (b) non implichi falsamente sponsorizzazione, pubblicità o approvazione del terzo che ha aggiunto il collegamento o dei suoi prodotti e/o servizi; (c) sia adatto al contesto del sito del terzo che ha aggiunto il collegamento.</p>

                <p>Nel caso in cui Lei rappresenti una delle organizzazioni indicate nel paragrafo 2 sopra e sia interessato ad aggiungere un collegamento al nostro sito, deve informarci inviando un'email a info@laus.app. Le chiediamo di includere nome, denominazione dell'organizzazione, informazioni di contatto e URL del sito, una lista di URL su cui intende aggiungere un collegamento al nostro sito e una lista di URL sul nostro sito che intende collegare. Le chiediamo di attendere 2-3 settimane per una risposta.</p>

                <p>Le organizzazioni approvate possono aggiungere un collegamento ipertestuale al nostro sito come segue:</p>
                <ul>
                    <li>Usando il nostro nome societario;</li>
                    <li>Usando l'uniform resource locator collegato;</li>
                    <li>Usando qualsiasi altra descrizione del nostro sito rilevante per contesto e formato dei contenuti sul sito terzo.</li>
                    <li>Non è consentito uso di logo o immagini del Comune di Lodi per collegamenti in caso di mancanza di un accordo di licenza.</li>
                </ul>

                <h2>Responsabilità sui contenuti</h2>
                <p>Non possiamo essere ritenuti responsabili per i contenuti presenti sul Suo sito. Accetta di proteggerci e difenderci da qualsiasi azione legale proveniente dal Suo sito. Non devono essere aggiunti collegamenti sul Suo sito che potrebbero essere interpretati come calunniosi, osceni, criminali o che infrangono, violano o suggeriscono la violazione di diritti di terzi.</p>

                <h2>Diritti riservati</h2>
                <p>Ci riserviamo il diritto di richiedere la rimozione di tutti i collegamenti o di un collegamento specifico al nostro sito. Deve approvare immediatamente la richiesta di rimozione dei collegamenti. Ci riserviamo il diritto di modificare termini e condizioni, oltre che politica di collegamento in qualsiasi momento. Continuando a usare il nostro sito, accetta di essere vincolato dai presenti termini e condizioni.</p>

                <h2>Rimozione di collegamenti dal nostro sito</h2>
                <p>Nel caso in cui un collegamento sul nostro sito sia offensivo per qualsiasi motivo, può contattarci e informarci in qualsiasi momento. Considereremo le richieste di rimozione dei collegamenti, tuttavia non siamo obbligati a completare la rimozione o rispondere direttamente.</p>

                <p>Non garantiamo l'accuratezza delle informazioni presenti sul sito. Non garantiamo che le informazioni siano complete o precise, né promettiamo di assicurare che il sito resti disponibile o che il materiale sul sito venga costantemente aggiornato.</p>

                <h2>Esclusione di responsabilità</h2>
                <p>Nella misura massima consentita dalla legge, escludiamo ogni rappresentanza, garanzia e condizione correlata alla nostra applicazione e all'uso della stessa. Niente in questa esclusione di responsabilità:</p>
                <ul>
                    <li>limita o esclude la nostra o la Sua responsabilità per decesso o lesioni personali;</li>
                    <li>limita o esclude la nostra o la Sua responsabilità per frode o rappresentazione errata fraudolenta;</li>
                    <li>limita la nostra o la Sua responsabilità in modo non consentito dalla legge;</li>
                    <li>esclude la nostra o la Sua responsabilità in modo non escludibile dalla legge.</li>
                    <li>Le limitazioni e i divieti di responsabilità indicati in questa sezione e in altre parti di questa esclusione di responsabilità: (a) sono soggetti al paragrafo precedente; (b) governano tutte le responsabilità correlate a questa esclusione di responsabilità, incluse quelle correlate a contratto, torto o violazione di obblighi normativi.</li>
                </ul>

                <p>Se applicazione, informazioni e servizi vengono forniti gratuitamente, non possiamo essere ritenuti responsabili per perdite o danni di qualsiasi natura.</p>
            </div>
        </div>
    )
}

export default Terms;
