// Components
import LogoButton from '../../components/Buttons/logo';

// Styles and Assets
import './Legal.scss';
import headerImage from '../../assets/img/lodi_02.jpg';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/sandwich.svg'

const Legal = ({ openSidebar }) => {
    return (
        <div className="page fixed legal">
            <div className="topbar">
                <div className="left">
                    <LogoButton />
                </div>
                <div className="center">
                    <div className="pill bg-silver">NOTE LEGALI</div>
                </div>
                <div className="right">
                    <div className="square-button hamburger" onClick={() => { openSidebar(true) }}>
                        <HamburgerIcon />
                    </div>
                </div>
            </div>

            <div className="header" style={{ backgroundImage: `url(${headerImage})` }}></div>

            <div className="body">
                <h2><span>1.</span> Premessa</h2>
                <p>
                    laus.app è l'applicazione ufficiale del Comune di Lodi.
                </p>

                <h2><span>2.</span> Licenza Creative Commons</h2>
                <p>
                    I contenuti di questa applicazione sito sono rilasciati sotto Licenza Creative Commons; salvo dove è diversamente specificato.
                    <br /><br />
                    Questo significa che, ove non diversamente specificato, i contenuti di questo sito sono liberamente distribuibili e riutilizzabili, a patto che sia sempre citata la fonte e riportato l'indirizzo web della pagina originale.
                    <br /><br />
                    I file presenti nell'applicazione espressamente destinati allo scaricamento (download) - come, ad esempio, le foto - sono liberamente e gratuitamente disponibili.
                    <br /><br />
                    L'amministrazione dell'applicazione compie ogni sforzo per evitare di utilizzare materiali altrui protetti da copyright ed è comunque disponibile a regolarizzare eventuali violazioni sfuggite al controllo redazionale.
                </p>

                <h2><span>3.</span> Privacy</h2>
                <p>
                    Il Comune di Lodi garantisce che il trattamento dei dati, che dovessero pervenire via posta elettronica o moduli elettronici di registrazione, è conforme a quanto previsto dalla normativa sulla privacy (D.lgs. n. 196/2003 e s.m.i.)
                </p>
            </div>
        </div>
    )
}

export default Legal;
