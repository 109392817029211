// React
import { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";

// Pages
import HelpDialog from '../../components/HelpDialog';

// Styles and Assets
import './Home.scss';
import { ReactComponent as TeddyBearIcon } from '../../assets/icons/teddy-bear.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as QuestionIcon } from '../../assets/icons/help.svg'

const Home = ({ user, setUser, isSidebarOpen }) => {
    const [ isHelpOpen, openHelp ] = useState(false);

    return (
        <div className="home page fixed">
            <div className="topbar">

            </div>

            <div className="main">
                <Link to="/map/issues">
                    <div className="square bg-yellow">
                        <div className="icon">
                            <TeddyBearIcon />
                        </div>
                        <div className="label pill">Segnalazioni</div>
                    </div>
                </Link>

                <Link to="/map/cleaning">
                    <div className="square bg-olive">
                        <div className="icon">
                            <TeddyBearIcon />
                        </div>
                        <div className="label pill">Verde pubblico</div>
                    </div>
                </Link>

                <Link to="/map/cleaning">
                    <div className="square bg-blue">
                        <div className="icon">
                            <TeddyBearIcon />
                        </div>
                        <div className="label pill">Parcheggi</div>
                    </div>
                </Link>

                <Link to="/map/cleaning">
                    <div className="square bg-teal">
                        <div className="icon">
                            <TeddyBearIcon />
                        </div>
                        <div className="label pill">Igiene urbana</div>
                    </div>
                </Link>
            </div>

            <div className="footer">
                <Link to="/login">
                    <div className="square-button bottom-left bg-white">
                        <UserIcon />
                    </div>
                </Link>

                <div className="square-button bottom-right bg-white" onClick={() => { openHelp(true) }}>
                    <QuestionIcon />
                </div>
            </div>

            <div id="help-wrapper" className={ isHelpOpen ? "open" : null }>
                <div className="page">
                    <div className="topbar fixed">
                        <div className="pill">BENVENUTO</div>
                    </div>

                    <HelpDialog onClose={ openHelp }>
                        <h2 className="text-center">Benvenuto su Laus App!</h2>
                        <h3 className="text-center">Comune di Lodi</h3>
                        <p>
                            Laus.App è uno strumento messo a disposizione dall'Amministrazione comunale di Lodi per raccogliere suggerimenti e segnalazioni relativi alla mobilità cittadina, a supporto dei lavori del <b>Piano Urbano della Mobilità Sostenibile</b> (PUMS).
                        </p>
                    </HelpDialog>
                </div>
            </div>

        </div>
    );
};

export default Home;
