// React
import { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster'

// Pages
import Help from '../../../pages/Help';

// Styles and Assets
import '../Map.scss';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg'
import { ReactComponent as QuestionIcon } from '../../../assets/icons/help.svg'
import { ReactComponent as PinIcon } from '../../../assets/icons/pin.svg'

const CleaningMap = ({ user, setUser, isSidebarOpen }) => {
    const [ isLoaderVisible, showLoader ] = useState(false);
    const [ markers, updateMarkers ] = useState([]);
    const [ categoriesDisabled, disableCategories ] = useState(false);
    const [ mapFilters, setMapFilters ] = useState({
        category_id: 0
    })
    const [ filtersPanelOpen, openFiltersPanel ] = useState(false);
    const [ isHelpOpen, openHelp ] = useState(false);

    let map = null;

    // const loadMarkers = useCallback((payload, filters = {}) => {
    //     let headers = {
    //         "Content-Type": "application/json",
    //     };

    //     if (user) {
    //         headers['Authorization'] = 'Bearer ' + user.access_token;
    //     }

    //     payload['context'] = 'issues';
    //     payload = { ...payload, ...filters }

    //     fetch(process.env.REACT_APP_API_BASE_URL + '/map', {
    //         method: 'POST',
    //         headers: headers,
    //         body: JSON.stringify(payload)
    //     })
    //     .then((response) => response.json())
    //     .then((response) => {
    //         updateMarkers(response.data.issues);
    //     })
    //     .catch((err) => {
    //         console.log(err.message);
    //     });
    // }, [ user ])

    // useEffect(() => {
    //     if (user && map) {
    //         const bounds = map.getBounds();
    //         const coordinates = {
    //             min_lat: bounds.getSouth(),
    //             max_lat: bounds.getNorth(),
    //             min_lon: bounds.getWest(),
    //             max_lon: bounds.getEast()
    //         }

    //         loadMarkers(coordinates);
    //     }
    // }, [ user, map, loadMarkers ]);

    const markerIcon = (marker) => {
        if (user && marker.user_id === user.id && marker.status === 'approved') {
            return new L.Icon({
                iconUrl: require("../../../assets/icons/pin-green.svg").default,
                iconSize: [ 40, 47 ],
                iconAnchor: [ 20, 47 ]
            });
        }

        if (user && marker.user_id === user.id && marker.status === 'pending') {
            return new L.Icon({
                iconUrl: require("../../../assets/icons/pin-yellow.svg").default,
                iconSize: [ 40, 47 ],
                iconAnchor: [ 20, 47 ]
            });
        }

        return new L.Icon({
            iconUrl: require("../../../assets/icons/pin.svg").default,
            iconSize: [ 40, 47 ],
            iconAnchor: [ 20, 47 ]
        });
    }

    const mapReady = (e) => {
        const map = e.target;
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        // loadMarkers(coordinates);
    }

    const onMarkerClick = (issue) => {
        // setIssue(null);
        const latlng = [ issue.lat, issue.lon ];
        const x = map.latLngToContainerPoint(latlng).x;
        const y = map.latLngToContainerPoint(latlng).y;
        const h1 = map.getSize().y * 0.75;
        const h = map.getSize().y / 2;
        const offset = (h1 - h) + 108;
        const point = map.containerPointToLatLng([ x, y - offset ]);
        map.panTo(point);
        // openIssue(true);
        // loadIssue(issue.id);
    }

    return (
        <div className="issues-map">
            <div id="map-wrapper">
                <MapContainer center={[ 45.3135139, 9.5040738 ]} zoom={ 13 } scrollWheelZoom={ true } zoomControl={ false } whenReady={ mapReady }>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                    <MarkerClusterGroup chunkedLoading>
                        { markers.map((marker, index) =>
                            <Marker
                                key={ `marker-${index}` }
                                position={[ marker.lat, marker.lon ]}
                                icon={ markerIcon(marker) }
                                eventHandlers={{ click: () => onMarkerClick(marker) }}
                            >
                            </Marker>
                        )}
                    </MarkerClusterGroup>
                </MapContainer>
            </div>
        </div>
    );
};

export default CleaningMap;
