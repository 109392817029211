// React
import { Link } from "react-router-dom";

const LogoButton = (props) => {
    return (
        <div className="square-button top-left logo">
            <Link to="/">
                <img className="logo" src="/img/favicons/android-chrome-384x384.png" alt="Comune di Lodi" />
            </Link>
        </div>
    )
}

export default LogoButton;
