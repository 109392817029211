// React
import React, { useState } from 'react';
import { useParams, Link } from "react-router-dom";

// Components
import Password from '../../components/Password';

// Styles and Assets
import headerImage from '../../assets/img/lodi_04.jpg';

const Reset = ({ user, setUser }) => {
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);
    let { reset_code } = useParams();

    const [ data, setData ] = useState({
        password: null,
        confirmPassword: null
    });

    const onReset = () => {
        const payload = { ...data, reset_code: reset_code };

        fetch(process.env.REACT_APP_API_BASE_URL + '/user/reset', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'success') {
                setSuccess(true);
                setUser(response.data.user);
            }
            else {
                setError(response.message);
            }
        })
        .catch((err) => {
            setError(err.message);
        });
    }

    const ResetButton = () => {
        if (data.password && data.confirmPassword) {
            return <div className="button primary full-width" onClick={() => onReset()}>REIMPOSTA PASSWORD</div>
        }
        else {
            return <div className="button secondary full-width">REIMPOSTA PASSWORD</div>
        }
    }

    return (
        <div className="page login">
            <div className="header" style={{ backgroundImage: `url(${headerImage})` }}></div>

            <div className="body">
                <h1>Reimposta la tua password</h1>

                { !success &&
                    <>
                        <p>
                            Inserisci una nuova password.
                        </p>
                        <div className="form">
                            <div className="field">
                                <div className="name">Nuova password</div>
                                <div className="value">
                                    <Password name="password" onChange={(e) => { setError(null); setData({ ...data, password: e.target.value }) }} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="name">Ripeti la nuova password</div>
                                <div className="value">
                                    <Password name="confirmPassword" onChange={(e) => { setError(null); setData({ ...data, confirmPassword: e.target.value }) }} />
                                </div>
                            </div>
                            { error &&
                                <div className="message">{ error }</div>
                            }
                            <div className="buttons">
                                <ResetButton />
                            </div>
                        </div>
                    </>
                }

                { success &&
                    <>
                        <p>
                            Complimenti! Hai completato la procedura di reimpostazione della tua password! Ora puoi continuare ad interagire con Laus.App!
                        </p>
                        <div className="form">
                            <div className="buttons">
                                <div className="button primary full-width">
                                    <Link to="/">VAI ALLA MAPPA</Link>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Reset;
