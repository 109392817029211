// React
import { useNavigate } from "react-router-dom";

// Components
import { default as LoginBox } from '../../components/Login';
import LogoButton from '../../components/Buttons/logo';

// Styles and Assets
import './Login.scss';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/sandwich.svg'

const Login = ({ setUser, deviceId, openSidebar }) => {
    const navigate = useNavigate();

    const onLoginSuccess = (user) => {
        setUser(user);
        navigate('/');
    }

    return (
        <div className="page fixed login">
            <div className="topbar">
                <div className="left">
                    <LogoButton />
                </div>
                <div className="center">

                </div>
                <div className="right">
                    <div className="square-button hamburger" onClick={() => { openSidebar(true) }}>
                        <HamburgerIcon />
                    </div>
                </div>
            </div>

            <div className="body">
                <LoginBox setUser={ onLoginSuccess } deviceId={ deviceId } />
            </div>
        </div>
    )
}

export default Login;
