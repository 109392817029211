// React
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, useMap, Marker } from 'react-leaflet';

// Components
import MapPopUp from '../../../components/Map/popup';
import LogoButton from '../../../components/Buttons/logo';
import HelpDialog from '../../../components/HelpDialog';

// Styles and Assets
import '../Map.scss';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/sandwich.svg'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg'
import { ReactComponent as QuestionIcon } from '../../../assets/icons/help.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as CleaningIcon } from '../../../assets/icons/cleaning.svg'

const MARKER_ICONS = {
    'unsorted': require("../../../assets/icons/marker_cleaning_unsorted.svg"),
    'batteries': require("../../../assets/icons/marker_cleaning_batteries.svg"),
    'glass': require("../../../assets/icons/marker_cleaning_glass.svg"),
    'medication': require("../../../assets/icons/marker_cleaning_medication.svg"),
    'collection_point': require("../../../assets/icons/marker_cleaning_collection_point.svg"),
    'clothes': require("../../../assets/icons/marker_cleaning_clothes.svg"),
    'dog_waste': require("../../../assets/icons/marker_cleaning_dog_waste.svg"),
    'vegetable_oil': require("../../../assets/icons/marker_cleaning_vegetable_oil.svg")
};

const cleaningCategories = [
    {
        'id': 'unsorted',
        'label': 'Cestini'
    },
    { 'id': 'batteries', 'label': 'Batterie scariche' },
    { 'id': 'clothes', 'label': 'Vestiti' },
    { 'id': 'collection_point', 'label': 'Isole ecologiche' },
    { 'id': 'dog_waste', 'label': 'Deiezioni canine' },
    { 'id': 'glass', 'label': 'Campane del vetro' },
    { 'id': 'medication', 'label': 'Farmaci scaduti' },
    { 'id': 'vegetable_oil', 'label': 'Oli vegetali' }
];

const CleaningMap = ({ user, setUser, openSidebar, isSidebarOpen }) => {
    const [ markers, updateMarkers ] = useState([]);
    const [ filtersPanelOpen, toggleFiltersPanel ] = useState(false);
    const [ isHelpOpen, toggleHelpDialog ] = useState(false);
    const [ item, setItem ] = useState(null);
    const [ mapFilters, setMapFilters ] = useState({
        category: null
    })

    let map = null;

    const loadMarkers = useCallback((payload, filters = {}) => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/map/cleaning')
            .then((response) => response.json())
            .then((response) => {
                updateMarkers(response.data.items);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [ user ])


    const markerIcon = (marker) => {
        return new L.Icon({
            iconUrl: MARKER_ICONS[marker.category].default,
            iconSize: [ 30, 37 ],
            iconAnchor: [ 15, 37 ]
        });
    }

    const mapReady = (e) => {
        const map = e.target;
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        loadMarkers(coordinates);
    }

    const filterMap = (context, filter) => {
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        let newFilters = { ...mapFilters, ...{ category: filter }};
        setMapFilters(newFilters);
        toggleFiltersPanel(false);

        let newMarkers = markers.map(obj => ({
            ...obj,
            hidden: ( filter === null || obj.category === filter ) ? false : true
        }));
        updateMarkers(newMarkers)
    }

    const onMarkerClick = (item) => {
        const nextMarkers = markers.map((marker) => {
            if (marker.id === item.id) {
                return { ...marker, hidden: false }
            }
            else {
                return { ...marker, hidden: true }
            }
        });
        updateMarkers(nextMarkers);

        setItem(item);
        const latlng = [ item.lat, item.lon ];
        map.setZoom(17, { animate: false });
        const x = map.latLngToContainerPoint(latlng).x;
        const y = map.latLngToContainerPoint(latlng).y;
        const h1 = map.getSize().y * 0.75;
        const h = map.getSize().y / 2;
        const offset = (h1 - h) + 88;
        const point = map.containerPointToLatLng([ x, y - offset ]);
        map.panTo(point);
    }

    const closePopup = (e) => {
        const nextMarkers = markers.map((marker) => {
            return { ...marker, hidden: false }
        });
        updateMarkers(nextMarkers);

        setItem(null);
    }

    const MapController = () => {
        map = useMap();
        return null
    }

    const ItemType = (needle) => {
        return cleaningCategories.find((category) => category.id === needle).label;
    }

    return (
        <div className="cleaning-map page fixed map">
            <div className="topbar bg-teal">
                <div className="left">
                    <LogoButton />
                </div>

                <div className="center">
                    <div className="pill bg-white">Igiene urbana</div>
                </div>

                <div className="right">
                    <div className="right">
                        <Link to="/">
                            <div className="square-button white">
                                <HomeIcon />
                            </div>
                        </Link>

                        <div className="square-button hamburger white" onClick={() => { openSidebar(true) }}>
                            <HamburgerIcon />
                        </div>
                    </div>
                </div>
            </div>

            <div className="main map">
                <div id="map-wrapper">
                    <MapContainer center={[ 45.3135139, 9.5040738 ]} zoom={ 13 } scrollWheelZoom={ true } zoomControl={ false } whenReady={ mapReady }>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        { markers.map((marker, index) => {
                            if (Object.hasOwn(marker, 'hidden') === false || marker.hidden === false) {
                                return (
                                    <Marker
                                        key={ `marker-${index}` }
                                        position={[ marker.lat, marker.lon ]}
                                        icon={ markerIcon(marker) }
                                        eventHandlers={{ click: () => onMarkerClick(marker) }}
                                    >
                                    </Marker>
                                )
                            }
                        })}

                        <MapController />
                    </MapContainer>
                </div>
            </div>

            <div className="footer bg-teal">
                <div className="left"></div>

                <div className="center">
                    <div className={ 'filters bg-teal ' + ( filtersPanelOpen ? 'open' : '' ) }>

                        <div className="active" onClick={() => { toggleFiltersPanel(!filtersPanelOpen) } }>
                            <div className="label white">
                                { mapFilters.category ? cleaningCategories.find(c => c.id === mapFilters.category).label : 'Tutti i tipi' }
                            </div>
                        </div>

                        <div className="list">
                            <div className="radio white">
                                <input id="filter_category_0" type="radio" name="filter_category" checked={ mapFilters.category === null } onChange={() => filterMap('category', null)} />
                                <label htmlFor="filter_category_0">Tutti i tipi</label>
                            </div>
                            { cleaningCategories && cleaningCategories.map((category, index) =>
                                <div key={ index } className="radio">
                                    <input
                                        id={ 'filter_category_' + category.id }
                                        type="radio"
                                        name="filter_category"
                                        onChange={() => filterMap('category', category.id) }
                                    />
                                    <label className="white" htmlFor={ 'filter_category_' + category.id }>{ category.label }</label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="right">
                    <div className="square-button white b-l-white question" onClick={() => { toggleHelpDialog(true) }}>
                        <QuestionIcon />
                    </div>
                </div>
            </div>

            <HelpDialog label="Igiene urbana" isHelpOpen={ isHelpOpen } toggleHelpDialog={ toggleHelpDialog }>
                <p className="text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="70 70 100 100" style={{ height: 240 }}>
                        <path fill="#33797c" d="M107.08806,89.89002c2.77761-4.46005,7.66622-7.15831,12.904-7.15831s10.12639,2.69825,12.904,7.15831l6.12662,9.79308,4.28546-2.47604c1.33325-.77773,2.99982-.66663,4.22197.26982s1.7618,2.52366,1.365,4.01564l-3.71407,13.8722c-.53965,2.03163-2.63477,3.2379-4.66639,2.69825l-13.8722-3.71407c-1.49197-.3968-2.58715-1.6507-2.79349-3.17442-.20634-1.52372.53965-3.03157,1.8729-3.79343l4.50767-2.60302-5.95203-9.50738c-.92058-1.4761-2.53953-2.38081-4.28546-2.38081s-3.36488.90471-4.28546,2.38081l-2.77761,4.44418c-1.46023,2.34907-4.53942,3.09506-6.92023,1.66657-2.42843-1.46023-3.20616-4.63465-1.69831-7.0472l2.77761-4.44418ZM147.53013,122.71348c2.38081-1.42849,5.46-.6825,6.92023,1.66657l3.87279,6.20598c1.49197,2.39669,2.28558,5.14255,2.31732,7.96779.04761,8.42808-6.77738,15.30069-15.20545,15.30069l-15.28481-.01587v5.07907c0,1.53959-.92058,2.93634-2.34907,3.5236-1.42849.58727-3.06331.26983-4.15849-.82535l-10.15813-10.15813c-1.49197-1.49197-1.49197-3.90453,0-5.38063l10.15813-10.15813c1.09517-1.09517,2.73-1.41261,4.15849-.82535s2.34907,1.98401,2.34907,3.5236v5.07907h15.26894c2.79349,0,5.06319-2.28558,5.04732-5.07907,0-.93645-.26983-1.85704-.76186-2.65064l-3.87279-6.20598c-1.50785-2.41256-.74599-5.58697,1.69831-7.0472h0ZM89.66052,117.79313l-4.58703-2.65064c-1.33325-.77773-2.07924-2.26971-1.87291-3.79343.20634-1.52372,1.30151-2.77761,2.79349-3.17442l13.8722-3.71407c2.03163-.53965,4.12674.66663,4.66639,2.69825l3.71407,13.85633c.3968,1.49197-.14285,3.06331-1.365,4.01564s-2.88872,1.04756-4.22197.26983l-4.2061-2.42843-8.17412,13.07859c-.49203.7936-.76186,1.71418-.76186,2.65064-.01587,2.79349,2.25384,5.07907,5.04732,5.07907h5.11081c2.80936,0,5.07907,2.26971,5.07907,5.07907s-2.26971,5.07907-5.07907,5.07907h-5.11081c-8.42808,0-15.25307-6.85674-15.20545-15.30069.01587-2.82523.80948-5.5711,2.31732-7.96779l7.98366-12.77703Z" />
                    </svg>
                </p>
                <h2 className="text-center">Igiene urbana</h2>
                <h3 className="text-center">Comune di Lodi</h3>
                <p>
                    Benvenuto nella sezione dedicata all'Igiene Urbana del nostro Comune! Qui troverai tutte le informazioni utili per una corretta gestione dei rifiuti e per contribuire a mantenere pulito e vivibile il nostro ambiente.
                </p>
                <h3 className="text-center">Mappa e Geolocalizzazione dei Punti di Raccolta</h3>
                <p>
                    Grazie alla mappa interattiva, potrai facilmente individuare i punti di raccolta più vicini a te. La geolocalizzazione ti permetterà di raggiungerli in modo rapido e comodo.
                </p>
                <h3 className="text-center">Tipologie di Punti di Raccolta</h3>
                <p>
                    <ul>
                        <li><b>Cestini per Rifiuti Generici</b> per i rifiuti indifferenziati di piccole dimensioni.</li>
                        <li><b>Campane del Vetro</b> per la raccolta differenziata del vetro.</li>
                        <li><b>Raccoglitori di Oli Vegetali Usati</b> per smaltire correttamente l'olio da cucina esausto.</li>
                        <li><b>Raccoglitori di Vestiti Usati</b> per donare abiti e tessuti in buono stato.</li>
                        <li><b>Farmaci Scaduti</b> per conferire in sicurezza i medicinali non più utilizzabili.</li>
                        <li><b>Batterie Scariche</b> per smaltire correttamente pile e batterie esauste.</li>
                        <li><b>Punti di Raccolta Multirifiuto</b> per la raccolta differenziata di secco, organico e altre tipologie di rifiuti.</li>
                    </ul>
                </p>
            </HelpDialog>

            <div id="popup-wrapper" className={ 'bg-teal ' + ( item ? 'open' : null )}>
                <div className="topbar fixed bg-teal">
                    <div className="left"></div>
                    <div className="center"></div>
                    <div className="right">
                        <div className="square-button white bg-teal close">
                            <CloseIcon onClick={ closePopup } />
                        </div>
                    </div>
                </div>
                { item &&
                    <MapPopUp key={ item.id } item={ item }>
                        <div className="row">
                            <div className="col-12 label">Tipo di raccolta</div>
                            <div className="col-12 text-bold">{ ItemType(item.category) }</div>
                        </div>
                    </MapPopUp>
                }
            </div>

        </div>
    );
};

export default CleaningMap;
