// React
import React, { useState, useCallback } from 'react';
import { Link } from "react-router-dom";

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';

// Components
import MapPopUp from '../../../components/Map/popup';
import LogoButton from '../../../components/Buttons/logo';
import HelpDialog from '../../../components/HelpDialog';

// Styles and Assets
import '../Map.scss';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/sandwich.svg'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg'
import { ReactComponent as QuestionIcon } from '../../../assets/icons/help.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'

const PARKING_ICON = require("../../../assets/icons/marker_parking.svg");

const ParkingsMap = ({ user, setUser, openSidebar, isSidebarOpen }) => {
    const [ markers, updateMarkers ] = useState([]);
    const [ isHelpOpen, toggleHelpDialog ] = useState(false);
    const [ filtersPanelOpen, toggleFiltersPanel ] = useState(false);
    const [ parking, setParking ] = useState(null);
    const [ mapFilters, setMapFilters ] = useState({
        type: null
    })
    const parkingTypes = [
        { 'id': 'structure', 'label': 'Struttura' },
        { 'id': 'level', 'label': 'A raso' }
    ]

    let map = null;

    const loadMarkers = useCallback((payload, filters = {}) => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/map/parkings')
            .then((response) => response.json())
            .then((response) => {
                updateMarkers(response.data.parkings);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [ user ])


    const markerIcon = (marker) => {
        return new L.Icon({
            iconUrl: PARKING_ICON.default,
            iconSize: [ 30, 37 ],
            iconAnchor: [ 15, 37 ]
        });
    }

    const mapReady = (e) => {
        const map = e.target;
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        loadMarkers(coordinates);
    }

    const onMarkerClick = (parking) => {
        const nextMarkers = markers.map((marker) => {
            if (marker.id === parking.id) {
                return { ...marker, opacity: 1 }
            }
            else {
                return { ...marker, opacity: 0.33 }
            }
        });
        updateMarkers(nextMarkers);

        setParking(parking);
        const latlng = [ parking.lat, parking.lon ];
        map.setZoom(17, { animate: false });
        const x = map.latLngToContainerPoint(latlng).x;
        const y = map.latLngToContainerPoint(latlng).y;
        const h1 = map.getSize().y * 0.75;
        const h = map.getSize().y / 2;
        const offset = (h1 - h) + 88;
        const point = map.containerPointToLatLng([ x, y - offset ]);
        map.panTo(point);
    }

    const closePopup = (e) => {
        const nextMarkers = markers.map((marker) => {
            return { ...marker, opacity: 1 }
        });
        updateMarkers(nextMarkers);

        setParking(null);
    }

    const filterMap = (context, filter) => {
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        let newFilters = { ...mapFilters, ...{ type: filter }};
        setMapFilters(newFilters);
        toggleFiltersPanel(false);

        let newMarkers = markers.map(obj => ({
            ...obj,
            hidden: ( filter === null || obj.type === filter ) ? false : true
        }));
        updateMarkers(newMarkers)
    }

    const MapController = () => {
        map = useMap();
        return null
    }

    const ParkingType = (type) => {
        switch (type) {
            case 'level':
                return 'A raso';

            case 'structure':
                return 'In struttura';

            default:
                return 'Sconosciuto';
        }
    }

    const Nl2Br = (text) => {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    return (
        <div className="parkings-map page fixed map">
            <div className="topbar bg-blue">
                <div className="left">
                    <LogoButton />
                </div>

                <div className="center">
                    <div className="pill bg-white">Parcheggi</div>
                </div>

                <div className="right">
                    <Link to="/">
                        <div className="square-button white">
                            <HomeIcon />
                        </div>
                    </Link>

                    <div className="square-button hamburger white" onClick={() => { openSidebar(true) }}>
                        <HamburgerIcon />
                    </div>
                </div>
            </div>

            <div className="main">
                <div id="map-wrapper">
                    <MapContainer center={[ 45.3135139, 9.5040738 ]} zoom={ 15 } scrollWheelZoom={ true } zoomControl={ false } whenReady={ mapReady }>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        { markers.map((marker, index) => {
                            if (Object.hasOwn(marker, 'hidden') === false || marker.hidden === false) {
                                return (
                                    <Marker
                                        key={ `marker-${index}` }
                                        position={[ marker.lat, marker.lon ]}
                                        icon={ markerIcon(marker) }
                                        eventHandlers={{ click: () => onMarkerClick(marker) }}
                                        opacity={ (Object.hasOwn(marker, 'opacity') ? marker.opacity : 1) }
                                    >
                                    </Marker>
                                )
                            }
                        })}

                        <MapController />
                    </MapContainer>
                </div>
            </div>

            <div className="footer bg-blue">
                <div className="left"></div>

                <div className="center">
                    <div className={ 'filters bg-blue ' + ( filtersPanelOpen ? 'open' : '' ) }>

                        <div className="active" onClick={() => { toggleFiltersPanel(!filtersPanelOpen) } }>
                            <div className="label white">
                                { mapFilters.type ? parkingTypes.find(c => c.id === mapFilters.type).label : 'Tutti i parcheggi' }
                            </div>
                        </div>

                        <div className="list">
                            <div className="radio white">
                                <input id="filter_category_0" type="radio" name="filter_category" checked={ mapFilters.type === null } onChange={() => filterMap('type', null)} />
                                <label htmlFor="filter_category_0">Tutti i parcheggi</label>
                            </div>
                            { parkingTypes && parkingTypes.map((type, index) =>
                                <div key={ index } className="radio">
                                    <input
                                        id={ 'filter_category_' + type.id }
                                        type="radio"
                                        name="filter_category"
                                        onChange={() => filterMap('type', type.id) }
                                    />
                                    <label className="white" htmlFor={ 'filter_category_' + type.id }>{ type.label }</label>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="right">
                    <div className="square-button white b-l-white question" onClick={() => { toggleHelpDialog(true) }}>
                        <QuestionIcon />
                    </div>
                </div>
            </div>

            <HelpDialog label="Parcheggi" isHelpOpen={ isHelpOpen } toggleHelpDialog={ toggleHelpDialog }>
                <p className="text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="70 70 100 100" style={{ height: 240 }}>
                        <path fill="#183b63" d="M93.21429,82.5c-5.9096,0-10.71429,4.80469-10.71429,10.71429v53.57143c0,5.9096,4.80469,10.71429,10.71429,10.71429h53.57143c5.9096,0,10.71429-4.80469,10.71429-10.71429v-53.57143c0-5.9096-4.80469-10.71429-10.71429-10.71429h-53.57143ZM114.64286,120h8.03571c2.96317,0,5.35714-2.39397,5.35714-5.35714s-2.39397-5.35714-5.35714-5.35714h-8.03571v10.71429ZM122.67857,130.71429h-8.03571v5.35714c0,2.96317-2.39397,5.35714-5.35714,5.35714s-5.35714-2.39397-5.35714-5.35714v-30.80357c0-3.69978,2.99665-6.69643,6.69643-6.69643h12.05357c8.87277,0,16.07143,7.19866,16.07143,16.07143s-7.19866,16.07143-16.07143,16.07143Z" />
                    </svg>
                </p>
                <h2 className="text-center">Parcheggi e aree di sosta</h2>
                <h3 className="text-center">Comune di Lodi</h3>
                <p>Trova facilmente il parcheggio più vicino a te! Utilizza la mappa per visualizzare i parcheggi disponibili, sia a raso che in struttura.</p>
                <h3>Parcheggi Disponibili</h3>
                <p>
                    <ul>
                        <li>Parcheggi a raso</li>
                        <li>Parcheggi in struttura</li>
                    </ul>
                </p>
                <p>Per alcuni parcheggi, è disponibile il dato in tempo reale sui posti disponibili. Clicca sul segnaposto del parcheggio per visualizzare i dettagli.</p>
            </HelpDialog>

            <div id="popup-wrapper" className={ 'bg-blue ' + ( parking ? 'open' : null )}>
                <div className="topbar fixed bg-blue">
                    <div className="left"></div>
                    <div className="center">
                        <div className="pill">PARCHEGGIO</div>
                    </div>
                    <div className="right">
                        <div className="square-button white bg-blue close">
                            <CloseIcon onClick={ closePopup } />
                        </div>
                    </div>
                </div>

                { parking &&
                    <MapPopUp key={ parking.id } item={ parking }>
                        <div className="row">
                            <div className="col-12 label">Nome</div>
                            <div className="col-12 text-bold">{ parking.name }</div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Tipo di parcheggio</div>
                            <div className="col-12">{ ParkingType(parking.type) }</div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Numero di posti</div>
                            <div className="col-12">{ parking.spaces }</div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Orari</div>
                            <div className="col-12" dangerouslySetInnerHTML={{ __html: Nl2Br(parking.time_table) }}></div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Tariffe</div>
                            <div className="col-12" dangerouslySetInnerHTML={{ __html: Nl2Br(parking.fees) }}></div>
                        </div>
                    </MapPopUp>
                }
            </div>

        </div>
    );
};

export default ParkingsMap;
