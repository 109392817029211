// React
import { Route, Routes } from "react-router-dom";

// Pages
import Home from "./Home";
import IssuesMap from "./Map/Issues";
import CleaningMap from "./Map/Cleaning";
import Profile from "./Profile";
import Issues from "./Issues";
import Privacy from "./Privacy";
import Legal from "./Legal";
import Terms from "./Terms";
import Register from "./Register";
import Activate from "./Activate";
import Login from "./Login";
import Forgot from "./Forgot";
import Reset from "./Reset";

const Router = ({ user, setUser, isSidebarOpen, deviceId }) => {
    const pagesData = [
        {
            path: "",
            element: <Home user={ user } setUser={ setUser } isSidebarOpen={ isSidebarOpen } />,
            title: "home"
        },
        {
            path: "/map/issues",
            element: <IssuesMap user={ user } setUser={ setUser } isSidebarOpen={ isSidebarOpen } />,
            title: "issues_map"
        },
        {
            path: "/map/cleaning",
            element: <CleaningMap user={ user } setUser={ setUser } isSidebarOpen={ isSidebarOpen } />,
            title: "cleaning_map"
        },
        {
            path: "/profile",
            element: <Profile user={ user } setUser={ setUser } />,
            title: "profile"
        },
        {
            path: "/issues",
            element: <Issues user={ user } setUser={ setUser } />,
            title: "issues"
        },
        {
            path: "/privacy",
            element: <Privacy />,
            title: "privacy"
        },
        {
            path: "/legal",
            element: <Legal />,
            title: "legal"
        },
        {
            path: "/terms",
            element: <Terms />,
            title: "terms"
        },
        {
            path: "/register",
            element: <Register />,
            title: "register"
        },
        {
            path: "/activate/:activation_code",
            element: <Activate user={ user } setUser={ setUser } />,
            title: "activate"
        },
        {
            path: "/forgot",
            element: <Forgot />,
            title: "forgot"
        },
        {
            path: "/reset/:reset_code",
            element: <Reset setUser={ setUser } />,
            title: "reset"
        },
        {
            path: "/login",
            element: <Login setUser={ setUser } deviceId={ deviceId } />,
            title: "login"
        }
    ];

    const pageRoutes = pagesData.map(({ path, title, element }: routerType) => {
        return <Route key={ title } path={ `/${path}` } element={ element } />;
    });

    return <Routes>{ pageRoutes }</Routes>;
};

export default Router;
