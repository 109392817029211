// React
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// IndexDB
import { del } from 'idb-keyval';

// Styles and Assets
import './Sidebar.scss';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

// Components
import ConfirmationDialog from '../../components/ConfirmationDialog';

const Sidebar = ({ isSidebarOpen, openSidebar, user, setUser, deviceId }) => {
    const [ isConfirmationDialogOpen, showConfirmationDialog ] = useState(false);
    const navigate = useNavigate();

    const UserMenu = () => {
        if (user?.access_token) {
            return (
                <>
                    <li className="border-top" onClick={() => { navigateTo('/profile') }}>Profilo</li>
                    <li onClick={() => { navigateTo('/issues') }}>Le Mie Segnalazioni</li>
                    <li onClick={() => { showConfirmationDialog(true) }}>Logout</li>
                    <li className="border-top small" onClick={() => { navigateTo('/legal') }}>Note Legali</li>
                    <li className="small" onClick={() => { navigateTo('/privacy') }}>Privacy</li>
                    <li className="small" onClick={() => { navigateTo('/terms') }}>Condizioni d'uso</li>
                </>
            )
        }
        else {
            return (
                <>
                    <li onClick={() => { navigateTo('/login') }}>Accedi</li>
                    <li onClick={() => { navigateTo('/register') }}>Registrati</li>
                    <li onClick={() => { navigateTo('/legal') }}>Note Legali</li>
                    <li onClick={() => { navigateTo('/privacy') }}>Privacy</li>
                    <li onClick={() => { navigateTo('/terms') }}>Condizioni d'uso</li>
                </>
            )
        }
    }

    const navigateTo = (path) => {
        openSidebar(false);
        navigate(path);
    }

    const logout = () => {
        showConfirmationDialog(false);
        let newUser = user;
        delete newUser.access_token;
        del('access_token').then(() => {
            //setUser(newUser);
            setUser(null);
            openSidebar(false);
            navigate('/');
            // window.location = '/';
        });
    }

    return (
        <div className={ isSidebarOpen ? 'sidebar open' : 'sidebar' }>
            <div className='square-button top-right'>
                <CloseIcon onClick={() => { openSidebar(false) }} />
            </div>

            <div className="content">
                { user?.access_token &&
                    <div className="header">
                        <div className="username">
                            { user?.first_name } { user?.last_name }
                        </div>
                        <div className="email">
                            { user?.email }
                        </div>
                    </div>
                }

                <div className="body">
                    <ul className="menu">
                        <UserMenu />
                    </ul>
                </div>

                <div className="footer">
                    Laus.app v2.0.1<br />
                    { (deviceId !== 0 && deviceId !== null) &&
                        <span style={{ color: '#ccc' }}>{ deviceId }</span>
                    }
                </div>
            </div>

            <ConfirmationDialog
                onClose={() => showConfirmationDialog(false)}
                open={ isConfirmationDialogOpen }
                onYes={ logout }
            >
                <p>Vuoi uscire dal tuo account?</p>
            </ConfirmationDialog>
        </div>
    )
}

export default Sidebar;
