// React
import React, { useState, useEffect } from 'react';

// Components
import Issue from '../../components/Issue';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import LogoButton from '../../components/Buttons/logo';

// Styles and Assets
import { ReactComponent as HamburgerIcon } from '../../assets/icons/sandwich.svg'

const Issues = ({ user, setUser, openSidebar }) => {
    const [ issues, setIssues ] = useState(null);
    const [ selectedIssue, showConfirmationDialog ] = useState(false);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/issues', {
            headers: {
                'Authorization': 'Bearer ' + user.access_token,
                'Content-Type': 'application/json',
            }
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'success') {
                setIssues(response.data.issues);
            }
            else {

            }
        })
        .catch((err) => {
        });
    }, [ user.access_token ]);

    const deleteIssue = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/issue/' + selectedIssue.id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.access_token,
                'Content-Type': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'success') {
                setIssues(issues.filter((issue) => issue.id !== response.data.issue.id));
            }
            else {
                console.log(response.message);
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
    }

    return (
        <div className="page issues fixed">
            <div className="topbar">
                <div className="left">
                    <LogoButton />
                </div>
                <div className="center">
                    <div className="pill bg-silver">LE MIE SEGNALAZIONI</div>
                </div>
                <div className="right">
                    <div className="square-button hamburger" onClick={() => { openSidebar(true) }}>
                        <HamburgerIcon />
                    </div>
                </div>
            </div>

            <div>
                { issues && issues.map((issue, index) =>
                    <div  key={ issue.id } className="item" style={{ borderBottom: '1px solid lightgray', marginBottom: '40px' }}>
                        <Issue issue={{ ...issue, fullHeight: true }}>
                            { issue.status === 'pending' &&
                                <div className="buttons" style={{ marginTop: '40px' }}>
                                    <div className="button full-width primary" onClick={() => showConfirmationDialog(issue)}>CANCELLA SEGNALAZIONE</div>
                                </div>
                            }
                        </Issue>
                    </div>
                )}

                { (issues === null || issues.length === 0) &&
                    <div className="body">
                        <p>Non hai ancora creato nessuna segnalazione</p>
                    </div>
                }
            </div>

            <ConfirmationDialog
                onClose={() => showConfirmationDialog(false)}
                open={ selectedIssue ? true : false }
                onYes={ deleteIssue }
            >
                <h2 style={{ textAlign: 'center' }}>Vuoi cancellare questa segnalazione?</h2>
                <p>{ selectedIssue.location_display_name }</p>
                <div className="categories" style={{ textAlign: 'center' }}>
                    { selectedIssue?.categories?.map((category) =>
                        <div key={ category.id } className="category">{ category.name }</div>
                    )}
                </div>
                <p>{ selectedIssue.text }</p>
            </ConfirmationDialog>
        </div>
    )
}

export default Issues;
