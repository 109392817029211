// React
import { Route, Routes } from "react-router-dom";

// Pages
import Home from "./Home";
import CersMap from "./Map/Cers";
import IssuesMap from "./Map/Issues";
import TreesMap from "./Map/Trees";
import CleaningMap from "./Map/Cleaning";
import ParkingsMap from "./Map/Parkings";
import Profile from "./Profile";
import Issues from "./Issues";
import Privacy from "./Privacy";
import Legal from "./Legal";
import Terms from "./Terms";
import Register from "./Register";
import Activate from "./Activate";
import Login from "./Login";
import Forgot from "./Forgot";
import Reset from "./Reset";

const Router = ({ user, setUser, openSidebar, isSidebarOpen, deviceId }) => {
    const pagesData = [
        {
            path: "",
            element: <Home user={ user } setUser={ setUser } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "home"
        },
        {
            path: "/map/issues",
            element: <IssuesMap user={ user } setUser={ setUser } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "issues_map"
        },
        {
            path: "/map/cers",
            element: <CersMap user={ user } setUser={ setUser } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "cers_map"
        },
        {
            path: "/map/trees",
            element: <TreesMap user={ user } setUser={ setUser } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "trees_map"
        },
        {
            path: "/map/cleaning",
            element: <CleaningMap user={ user } setUser={ setUser } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "cleaning_map"
        },
        {
            path: "/map/parkings",
            element: <ParkingsMap user={ user } setUser={ setUser } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "cleaning_map"
        },
        {
            path: "/profile",
            element: <Profile user={ user } setUser={ setUser } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "profile"
        },
        {
            path: "/issues",
            element: <Issues user={ user } setUser={ setUser } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "issues"
        },
        {
            path: "/privacy",
            element: <Privacy openSidebar={ openSidebar } />,
            title: "privacy"
        },
        {
            path: "/legal",
            element: <Legal openSidebar={ openSidebar } />,
            title: "legal"
        },
        {
            path: "/terms",
            element: <Terms openSidebar={ openSidebar } />,
            title: "terms"
        },
        {
            path: "/register",
            element: <Register openSidebar={ openSidebar } />,
            title: "register"
        },
        {
            path: "/activate/:activation_code",
            element: <Activate user={ user } setUser={ setUser } />,
            title: "activate"
        },
        {
            path: "/forgot",
            element: <Forgot openSidebar={ openSidebar } />,
            title: "forgot"
        },
        {
            path: "/reset/:reset_code",
            element: <Reset setUser={ setUser } openSidebar={ openSidebar } />,
            title: "reset"
        },
        {
            path: "/login",
            element: <Login setUser={ setUser } deviceId={ deviceId } openSidebar={ openSidebar } isSidebarOpen={ isSidebarOpen } />,
            title: "login"
        }
    ];

    const pageRoutes = pagesData.map(({ path, title, element }: routerType) => {
        return <Route key={ title } path={ `/${path}` } element={ element } />;
    });

    return <Routes>{ pageRoutes }</Routes>;
};

export default Router;
