// React
import React, { useState } from 'react';

// Date-fns
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { it } from 'date-fns/locale';

// Styles and Assets
import './PopUp.scss';

const MapPopUp = ({ item, children }) => {
    const [ activeDot, setActiveDot ] = useState(0);

    const handleScroll = (e) => {
        let dotIndex = 0;
        dotIndex = Math.floor(e.target.scrollLeft / e.target.offsetWidth);
        if (activeDot !== dotIndex) {
            setActiveDot(dotIndex);
        }
    }

    return (
        <div id="popup" className={ item.fullHeight ? 'full-height' : '' }>
            { item.photos && item.photos.length > 0 &&
                <div className="photos">
                    <div className="slider" onScroll={ handleScroll }>
                        { item.photos.map((photo, index) =>
                            <div key={ photo.id } className="photo">
                                <img src={ photo.url } alt="" />
                            </div>
                        )}
                    </div>
                    { item.photos.length > 1 &&
                        <div className="dots">
                            { item.photos.map((photo, index) =>
                                <div key={ photo.id } className={ index === activeDot ? 'dot active' : 'dot' }></div>
                            )}
                        </div>
                    }
                </div>
            }

            <div className="body">
                { children }
            </div>
        </div>
    )
}

export default MapPopUp;
