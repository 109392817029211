// React
import React, { useState } from 'react';
import { Link } from "react-router-dom";

// IndexDB
import { set } from 'idb-keyval';

// Components
import Password from '../../components/Password';

// Styles and Icons
import { ReactComponent as LifeRingIcon } from '../../assets/icons/lifering.svg'

const Login = ({ setUser, deviceId }) => {
    const [ error, setError ] = useState(null);
    const [ data, setData ] = useState({
        email: null,
        password: null
    });

    const onLogin = () => {
        let payload = {
            email: data.email,
            password: data.password
        }

        if (deviceId) {
            payload.device_id = deviceId;
        }

        fetch(process.env.REACT_APP_API_BASE_URL + '/user/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'success') {
                if (setUser) {
                    if (response.data.user.access_token) {
                        set('access_token', response.data.user.access_token);
                    }
                    setUser(response.data.user);
                }
            }
            else {
                setError(response.message);
            }
        })
        .catch((err) => {
            console.error(err.message);
        });
    }

    const LoginButton = () => {
        if (data.email && data.password) {
            return <div className="button primary full-width" onClick={() => onLogin()}>LOGIN</div>
        }
        else {
            return <div className="button secondary full-width">LOGIN</div>
        }
    }

    return (
        <div className="form">
            <h1>Accedi</h1>
            <p>
                Per effettuare una segnalazione esegui l'accesso.
            </p>

            <div className="field">
                <div className="name">Indirizzo email</div>
                <div className="value">
                    <input name="email" type="text" onChange={ (e) => { setError(null); setData({ ...data, email: e.target.value }) }} />
                </div>
            </div>

            <div className="field">
                <div className="name">Password</div>
                <div className="value">
                    <Password name="password" onChange={ (e) => { setError(null); setData({ ...data, password: e.target.value }) }} />
                </div>
            </div>

            { error &&
                <div className="message">{ error }</div>
            }

            <div className="buttons">
                <LoginButton />
            </div>

            <div className="footer" style={{ marginTop: '90px' }}>
                <div className="helper">
                    <div className="title">
                        <LifeRingIcon /> AIUTO
                    </div>
                    <Link to="/forgot">Ho dimenticato la password</Link>
                </div>
            </div>
        </div>
    )
}

export default Login;
