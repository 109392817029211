// React
import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

// Leaflet
import L from "leaflet";

// Components
import MapPopUp from '../../../components/Map/popup';
import LogoButton from '../../../components/Buttons/logo';
import HelpDialog from '../../../components/HelpDialog';

// Styles and Assets
import '../Map.scss';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/sandwich.svg'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg'
import { ReactComponent as QuestionIcon } from '../../../assets/icons/help.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'

const TreesMap = ({ user, setUser, openSidebar, isSidebarOpen }) => {
    const mapRef = useRef();
    let map = useRef(undefined);
    let speciesData = useRef();

    const [ species, setSpecies ] = useState(false);
    const [ tree, setTree ] = useState(null);
    const [ isHelpOpen, toggleHelpDialog ] = useState(false);
    const [ filtersPanelOpen, toggleFiltersPanel ] = useState(false);
    const [ mapFilters, setMapFilters ] = useState({
        species_id: null
    })

    useEffect(()=> {
        if (!map.current) {
            map.current = L.map(mapRef.current, {
                center: [ 45.3140617, 9.505 ],
                preferCanvas: true,
                zoomControl: false,
                zoom: 15
            });

            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
            }).addTo(map.current);
        }

        if (species === false) {
            fetch(process.env.REACT_APP_API_BASE_URL + "/map/trees")
                .then((response) => response.json())
                .then((response) => {
                    speciesData.current = response.data.species;
                    setSpecies(true);

                    for (let tree of response.data.trees) {
                        if (tree.species_id) {
                            let marker = L.circleMarker([ tree.lat, tree.lon ], {
                                color: '#3D9970',
                                radius: 5,
                                weight: 4,
                                fillOpacity: 1,
                                fillColor: '#ffffff'
                            });

                            marker.species_id = tree.species_id;
                            marker.scope = tree.scope;
                            marker.label = tree.label;
                            marker.on('click', (e) => onMarkerClick(e, tree.species_id));
                            marker.addTo(map.current);
                        }
                    }
            });
        }
    }, [ ])

    const filterMap = (context, filter) => {
        let newFilters = { ...mapFilters, ...{ species_id: filter }};
        setMapFilters(newFilters);
        toggleFiltersPanel(false);
        map.current.eachLayer((marker) => {
            if ('species_id' in marker) {
                if (filter === null || marker.species_id === filter) {
                    marker.setStyle({
                        opacity: 1,
                        fillOpacity: 1,
                        fill: true,
                        stroke: true
                    });
                }
                else {
                    marker.setStyle({
                        opacity: 0.2,
                        fillOpacity: 0.2,
                        fill: false,
                        stroke: false
                    });
                }
            }
        })
    }

    const onMarkerClick = (e, species_id) => {
        map.current.eachLayer(layer => {
            if (Object.hasOwn(layer, 'species_id')) {
                if (e.target.label === layer.label) {
                    layer.setStyle({
                        opacity: 1,
                        fillOpacity: 1
                    });
                }
                else {
                    layer.setStyle({
                        opacity: 0.3,
                        fillOpacity: 0.3
                    });
                }
            }
        });

        let species = speciesData.current.find((s => s.id === species_id));
        let photos = [];
        for (let photoCategory of [ 'description', 'leaves', 'fruits' ]) {
            if (Object.hasOwn(species, 'photos_' + photoCategory)) {
                for (let photo of species['photos_' + photoCategory]) {
                    photos.push({
                        id: photo.id,
                        url: photo.url
                    });
                }
            }
        }
        setTree({
            scope: e.target.scope,
            label: e.target.label,
            species: species,
            photos: photos
        });

        const latlng = [ e.latlng.lat, e.latlng.lng ];
        const x = map.current.latLngToContainerPoint(latlng).x;
        const y = map.current.latLngToContainerPoint(latlng).y;
        const h1 = map.current.getSize().y * 0.75;
        const h = map.current.getSize().y / 2;
        const offset = (h1 - h) + 60;
        const point = map.current.containerPointToLatLng([ x, y - offset ]);
        map.current.panTo(point);
    }

    const closePopup = (e) => {
        setTree(null);
        map.current.eachLayer(layer => {
            if (Object.hasOwn(layer, 'species_id')) {
                layer.setStyle({
                    opacity: 1,
                    fillOpacity: 1
                });
            }
        });
    }

    return (
        <div className="trees-map page fixed map">
            <div className="topbar bg-olive">
                <div className="left">
                    <LogoButton />
                </div>

                <div className="center">
                    <div className="pill bg-white">Verde pubblico</div>
                </div>

                <div className="right">
                    <div className="right">
                        <Link to="/">
                            <div className="square-button white">
                                <HomeIcon />
                            </div>
                        </Link>

                        <div className="square-button hamburger white" onClick={() => { openSidebar(true) }}>
                            <HamburgerIcon />
                        </div>
                    </div>
                </div>
            </div>

            <div className="main map">
                <div id="map-wrapper">
                    <div ref={ mapRef } />
                </div>
            </div>

            <div className="footer bg-olive">
                <div className="left"></div>

                <div className="center">
                    <div className={ 'filters bg-olive ' + ( filtersPanelOpen ? 'open' : '' ) }>

                        <div className="active" onClick={() => { toggleFiltersPanel(!filtersPanelOpen) } }>
                            <div className="label white">
                                { mapFilters.species_id ? speciesData.current.find(c => c.id === mapFilters.species_id).common_name : 'Tutte le specie' }
                            </div>
                        </div>

                        { species === true > 0 &&
                            <div className="list">
                                <div className="radio white">
                                    <input id="filter_category_0" type="radio" name="filter_category" checked={ mapFilters.species_id === null } onChange={() => filterMap('species_id', null)} />
                                    <label htmlFor="filter_category_0">Tutte le specie</label>
                                </div>
                                { speciesData.current.map((species, index) =>
                                    <div key={ index } className="radio">
                                        <input
                                            id={ 'filter_category_' + species.id }
                                            type="radio"
                                            name="filter_category"
                                            onChange={() => filterMap('species_id', species.id) }
                                        />
                                        <label className="white" htmlFor={ 'filter_category_' + species.id }>
                                            <div className="line">{ species.common_name } ({ species.trees })</div>
                                            <div className="line"><small>{ species.name }</small></div>
                                        </label>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>

                <div className="right">
                    <div className="square-button white b-l-white question" onClick={() => { toggleHelpDialog(true) }}>
                        <QuestionIcon />
                    </div>
                </div>
            </div>

            <HelpDialog label="Verde pubblico" isHelpOpen={ isHelpOpen } toggleHelpDialog={ toggleHelpDialog }>
                <p className="text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="70 70 100 100" style={{ height: 240 }}>
                        <path fill="#236046" d="M149.0553,111.60205c.90045-1.64169,1.41345-3.52607,1.41345-5.53064,0-6.36116-5.15676-11.51786-11.51786-11.51786-1.73545,0-3.37996.38619-4.85583,1.07357-.48187-7.32991-6.57626-13.12712-14.02814-13.12712-7.39666,0-13.45506,5.71184-14.01573,12.96468-1.41778-.58601-2.97082-.91114-4.60031-.91114-6.65703,0-12.0536,5.39657-12.0536,12.05355,0,1.99247.48641,3.87023,1.34238,5.52594-5.10039,2.02506-8.70843,7.00067-8.70843,12.82228,0,7.61859,6.17604,13.79468,13.79463,13.79468h19.82143v14.47825c0,2.34946,1.92229,4.27175,4.27175,4.27175h.29582c2.34946,0,4.27175-1.92229,4.27175-4.27175v-14.47825h19.28569v-.00339c.04484.00042.08901.00339.13396.00339,7.76647,0,14.0625-6.29603,14.0625-14.0625,0-5.94832-3.69507-11.03025-8.91346-13.08545Z" />
                    </svg>
                </p>
                <h2 className="text-center">Verde pubblico</h2>
                <h3 className="text-center">Comune di Lodi</h3>
                <p>
                    Esplora il patrimonio arboreo della nostra città! Utilizza la mappa per visualizzare la posizione di tutti gli alberi e scopri le loro caratteristiche.
                </p>

                <h3>Mappa degli Alberi</h3>

                <p>Clicca sul segnaposto di un albero per visualizzare la scheda dettagliata della specie.</p>

                <h3>Scheda della Specie</h3>

                <p>
                    <ul>
                        <li><b>Informazioni Generali</b> Nome scientifico e comune, dimensioni medie e portamento, longevità e caratteristiche generali.</li>
                        <li><b>Origine e Provenienza</b> Area geografica di origine, modalità di introduzione nella città, diffusione e adattamento.</li>
                        <li><b>Foglie</b> Tipo di foglia (semplice, composta, ecc.), forma e dimensione, colore e venature, eventuali particolarità (es. profumo, consistenza).</li>
                        <li><b>Fiori</b> Periodo di fioritura, colore e forma dei fiori, profumo e impollinazione.</li>
                        <li><b>Frutti</b> Tipo di frutto (bacca, drupa, ecc.), dimensioni e colore, periodo di maturazione, eventuali usi (alimentari, medicinali, ecc.).</li>
                    </ul>
                </p>
            </HelpDialog>

            <div id="popup-wrapper" className={ 'bg-olive ' + ( tree ? 'open' : null )}>
                <div className="topbar fixed bg-olive">
                    <div className="left"></div>
                    <div className="center">
                        <div className="pill">VERDE PUBBLICO</div>
                    </div>
                    <div className="right">
                        <div className="square-button white bg-olive close">
                            <CloseIcon onClick={ closePopup } />
                        </div>
                    </div>
                </div>
                { tree &&
                    <MapPopUp key={ tree.label } item={ tree }>
                        <div className="row">
                            <div className="col-12 label">Nome comune</div>
                            <div className="col-12 text-bold">{ tree.species.common_name }</div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Nome scientifico</div>
                            <div className="col-12 text-bold">{ tree.species.name }</div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Descrizione generale</div>
                            <div className="col-12">{ tree.species.description }</div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Habitat e origine</div>
                            <div className="col-12">{ tree.species.habitat }</div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Foglie</div>
                            <div className="col-12">{ tree.species.leaves }</div>
                        </div>
                        <div className="row">
                            <div className="col-12 label">Fiori e frutti</div>
                            <div className="col-12">{ tree.species.fruits }</div>
                        </div>
                    </MapPopUp>
                }
            </div>

        </div>
    );
};

export default TreesMap;
