const HelpDialog = ({ children, onClose }) => {
    return (
        <div className="help">
            <div className="header">
                <img className="logo" src="/img/favicons/android-chrome-384x384.png" alt="Comune di Lodi" />
            </div>

            <div className="body">
                { children }
            </div>

            <div className="footer">
                <div className="buttons">
                    <div className="button primary" onClick={() => { onClose(false) }}>HO CAPITO</div>
                </div>
            </div>
        </div>
    )
}

export default HelpDialog;
