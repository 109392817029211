// React
import React, { useState, useCallback } from 'react';
import { Link } from "react-router-dom";

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';

// Components
import MapPopUp from '../../../components/Map/popup';
import LogoButton from '../../../components/Buttons/logo';
import HelpDialog from '../../../components/HelpDialog';

// Styles and Assets
import '../Map.scss';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/sandwich.svg'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg'
import { ReactComponent as QuestionIcon } from '../../../assets/icons/help.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'

const CersMap = ({ user, setUser, openSidebar, isSidebarOpen }) => {
    const [ markers, updateMarkers ] = useState([]);
    const [ isHelpOpen, toggleHelpDialog ] = useState(false);
    const [ filtersPanelOpen, toggleFiltersPanel ] = useState(false);
    const [ plant, setPlant ] = useState(null);
    const [ mapFilters, setMapFilters ] = useState({
        type: null
    })
    const plantTypes = [
        { 'id': 'structure', 'label': 'Struttura' },
        { 'id': 'level', 'label': 'A raso' }
    ]

    let map = null;

    const loadMarkers = useCallback((payload, filters = {}) => {
        // fetch(process.env.REACT_APP_API_BASE_URL + '/map/plants')
        //     .then((response) => response.json())
        //     .then((response) => {
        //         updateMarkers(response.data.parkings);
        //     })
        //     .catch((err) => {
        //         console.log(err.message);
        //     });
    }, [ user ])


    const markerIcon = (marker) => {
        // return new L.Icon({
        //     iconUrl: PARKING_ICON.default,
        //     iconSize: [ 30, 37 ],
        //     iconAnchor: [ 15, 37 ]
        // });
    }

    const mapReady = (e) => {
        const map = e.target;
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        loadMarkers(coordinates);
    }

    const onMarkerClick = (parking) => {
        // const nextMarkers = markers.map((marker) => {
        //     if (marker.id === parking.id) {
        //         return { ...marker, opacity: 1 }
        //     }
        //     else {
        //         return { ...marker, opacity: 0.33 }
        //     }
        // });
        // updateMarkers(nextMarkers);

        // setParking(parking);
        // const latlng = [ parking.lat, parking.lon ];
        // map.setZoom(17, { animate: false });
        // const x = map.latLngToContainerPoint(latlng).x;
        // const y = map.latLngToContainerPoint(latlng).y;
        // const h1 = map.getSize().y * 0.75;
        // const h = map.getSize().y / 2;
        // const offset = (h1 - h) + 88;
        // const point = map.containerPointToLatLng([ x, y - offset ]);
        // map.panTo(point);
    }

    const closePopup = (e) => {
        const nextMarkers = markers.map((marker) => {
            return { ...marker, opacity: 1 }
        });
        updateMarkers(nextMarkers);

        setPlant(null);
    }

    const filterMap = (context, filter) => {
        const bounds = map.getBounds();
        const coordinates = {
            min_lat: bounds.getSouth(),
            max_lat: bounds.getNorth(),
            min_lon: bounds.getWest(),
            max_lon: bounds.getEast()
        }

        let newFilters = { ...mapFilters, ...{ type: filter }};
        setMapFilters(newFilters);
        toggleFiltersPanel(false);

        let newMarkers = markers.map(obj => ({
            ...obj,
            hidden: ( filter === null || obj.type === filter ) ? false : true
        }));
        updateMarkers(newMarkers)
    }

    const MapController = () => {
        map = useMap();
        return null
    }

    const ParkingType = (type) => {
        switch (type) {
            case 'level':
                return 'A raso';

            case 'structure':
                return 'In struttura';

            default:
                return 'Sconosciuto';
        }
    }

    const Nl2Br = (text) => {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    return (
        <div className="cers-map page fixed map">
            <div className="topbar bg-yellow">
                <div className="left">
                    <LogoButton />
                </div>

                <div className="center">
                    <div className="pill bg-white">CERS</div>
                </div>

                <div className="right">
                    <Link to="/">
                        <div className="square-button white">
                            <HomeIcon />
                        </div>
                    </Link>

                    <div className="square-button hamburger white" onClick={() => { openSidebar(true) }}>
                        <HamburgerIcon />
                    </div>
                </div>
            </div>

            <div className="main">
                <div id="map-wrapper">
                    <MapContainer center={[ 45.3135139, 9.5040738 ]} zoom={ 15 } scrollWheelZoom={ true } zoomControl={ false } whenReady={ mapReady }>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        <MapController />
                    </MapContainer>
                </div>
            </div>

            <div className="footer bg-yellow">
                <div className="left"></div>

                <div className="center">
                </div>

                <div className="right">
                    <div className="square-button white b-l-white question" onClick={() => { toggleHelpDialog(true) }}>
                        <QuestionIcon />
                    </div>
                </div>
            </div>

            <HelpDialog label="Parcheggi" isHelpOpen={ isHelpOpen } toggleHelpDialog={ toggleHelpDialog }>
                <h2 className="text-center">Benvenuto su Laus App!</h2>
                <h3 className="text-center">Comune di Lodi</h3>
            </HelpDialog>

            <div id="popup-wrapper" className={ 'bg-blue ' + ( plant ? 'open' : null )}>
                <div className="topbar fixed bg-blue">
                    <div className="left"></div>
                    <div className="center">
                        <div className="pill">IMPIANTO ENERGETICO</div>
                    </div>
                    <div className="right">
                        <div className="square-button white bg-blue close">
                            <CloseIcon onClick={ closePopup } />
                        </div>
                    </div>
                </div>

                { plant &&
                    <MapPopUp key={ plant.id } item={ plant }>
                    </MapPopUp>
                }
            </div>

        </div>
    );
};

export default CersMap;
