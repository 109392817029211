// React
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";

// Components
import LogoButton from '../../components/Buttons/logo';
import HelpDialog from '../../components/HelpDialog';

// Styles and Assets
import './Home.scss';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/sandwich.svg'
import { ReactComponent as CersIcon } from '../../assets/icons/cers.svg'
import { ReactComponent as IssuesIcon } from '../../assets/icons/issues.svg'
import { ReactComponent as TreesIcon } from '../../assets/icons/trees.svg'
import { ReactComponent as ParkingIcon } from '../../assets/icons/parkings.svg'
import { ReactComponent as CleaningIcon } from '../../assets/icons/cleaning.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as QuestionIcon } from '../../assets/icons/help.svg'

const Home = ({ user, setUser, openSidebar, isSidebarOpen }) => {
    const [ isHelpOpen, toggleHelpDialog ] = useState(false);

    return (
        <div className="home page fixed">
            <div className="topbar">
                <div className="left">
                    <LogoButton />
                </div>
                <div className="center">

                </div>
                <div className="right">
                    <div className="square-button hamburger" onClick={() => { openSidebar(true) }}>
                        <HamburgerIcon />
                    </div>
                </div>
            </div>

            <div className="main">
                { false &&
                    <Link to="/map/cers">
                        <div className="square bg-yellow">
                            <div className="icon">
                                <CersIcon />
                            </div>
                            <div className="label pill">Comunità Energetica</div>
                        </div>
                    </Link>
                }
                <Link to="/map/issues">
                    <div className="square bg-yellow">
                        <div className="icon">
                            <IssuesIcon />
                        </div>
                        <div className="label pill">Segnalazioni mobilità</div>
                    </div>
                </Link>

                <Link to="/map/trees">
                    <div className="square bg-olive">
                        <div className="icon">
                            <TreesIcon />
                        </div>
                        <div className="label pill">Verde pubblico</div>
                    </div>
                </Link>

                <Link to="/map/parkings">
                    <div className="square bg-blue">
                        <div className="icon">
                            <ParkingIcon />
                        </div>
                        <div className="label pill">Parcheggi</div>
                    </div>
                </Link>

                <Link to="/map/cleaning">
                    <div className="square bg-teal">
                        <div className="icon">
                            <CleaningIcon />
                        </div>
                        <div className="label pill">Igiene urbana</div>
                    </div>
                </Link>
            </div>

            <div className="footer">
                <div className="left">
                    <Link to="/login">
                        <div className="square-button bg-white">
                            <UserIcon />
                        </div>
                    </Link>
                </div>

                <div className="right">
                    <div className="square-button bg-white" onClick={() => { toggleHelpDialog(true) }}>
                        <QuestionIcon />
                    </div>
                </div>
            </div>

            <HelpDialog label="Benvenuto" isHelpOpen={ isHelpOpen } toggleHelpDialog={ toggleHelpDialog }>
                <h2 className="text-center">Benvenuto su Laus App!</h2>
                <h3 className="text-center">Comune di Lodi</h3>
                <p>
                    Laus.app è stata creata per fornirti un accesso facile e immediato alle informazioni utili sulla nostra città. Grazie alle mappe interattive, potrai:
                </p>
                <p>
                    <ul>
                        <li><b>Trovare parcheggi</b> Individua i parcheggi disponibili nelle diverse zone della città.</li>
                        <li><b>Localizzare le zone ecologiche di raccolta</b> Scopri dove si trovano i punti di raccolta differenziata più vicini a te.</li>
                        <li><b>Esplorare il verde pubblico</b> Visualizza la posizione degli alberi pubblici e delle aree verdi della città.</li>
                        <li><b>Segnalare la mobilità sostenibile</b> Invia segnalazioni e suggerimenti per migliorare la mobilità sostenibile a Lodi.</li>
                    </ul>
                </p>
                <p>Utilizza le mappe interattive per esplorare i luoghi di interesse. Seleziona le diverse categorie per filtrare i risultati. Invia le tue segnalazioni in modo semplice e veloce.</p>
                <p>Grazie per aver scelto di utilizzare la nostra app.</p>
            </HelpDialog>

        </div>
    );
};

export default Home;
