// React
import React, { useState, useEffect } from 'react';
import { useParams, Link } from "react-router-dom";

// Styles and Assets
import headerImage from '../../assets/img/lodi_05.jpg';

const Activate = ({ user, setUser }) => {
    const [ message, setMessage ] = useState(null);
    let { activation_code } = useParams();

    useEffect(() => {
        if (!user) {
            fetch(process.env.REACT_APP_API_BASE_URL + '/user/activate/' + activation_code, {
                headers: {
                    "Content-Type": "application/json",
                }
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === 'success') {
                    setMessage('Il tuo indirizzo email è stato correttamente confermato! Puoi cominciare a interagire con Laus.App!');
                    setUser(response.data.user);
                }
                else {
                    setMessage(response.message);
                }
            })
            .catch((err) => {
                setMessage(err.message);
            });
        }
    }, [ user, setUser, activation_code ]);

    return (
        <div className="page activate">
            <div className="header" style={{ backgroundImage: `url(${headerImage})` }}></div>

            <div className="body">
                <h1>Attivazione account</h1>
                { message &&
                    <p>{ message }</p>
                }
                <div className="form">
                    <div className="buttons">
                        <div className="button primary full-width">
                            <Link to="/">VAI ALLA MAPPA</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activate;
